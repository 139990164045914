body {
	&._lock {
		overflow: hidden;

		@media (min-width:1281px) {
			overflow: auto;
		}
	}
}

.App {
	background-color: #fff;
	font-family: "Roboto", sans-serif;

	.hide {
		display: none;
	}

	header {
		width: calc(100% - 40px);
		max-width: 1220px;
		margin: 0 auto;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		padding: 30px 0;
		gap: 30px;
		border-bottom: 1px solid #E5E5E5;

		@media (min-width:1281px) {
			padding: 55px 0 45px;
			gap: 40px;
		}

		.subtitle {}

		.return {
			display: none;
		}

		.burger {
			display: none;
		}

		&.not-first {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

			@media (min-width:1281px) {
				flex-flow: column nowrap;
			}

			.return {
				display: block;

				@media (min-width:1281px) {
					display: none;
				}
			}

			.burger {
				display: block;

				@media (min-width:1281px) {
					display: none;
				}
			}
		}
	}

	.title {
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 28px;
		color: #000000;

		@media (min-width:1281px) {
			font-size: 36px;
			line-height: 42px;
		}
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #929292;

		@media (min-width:1281px) {
			font-size: 24px;
			line-height: 28px;
		}
	}

	main {
		padding: 30px 0;
		width: calc(100% - 40px);
		max-width: 1220px;
		margin: 0 auto;
		position: relative;

		.title {
			font-size: 18px;
			line-height: 21px;
			text-align: center;
			margin-bottom: 30px;

			@media (min-width:1281px) {
				font-size: 24px;
				line-height: 28px;
				text-align: left;
				margin-bottom: 45px;
			}
		}

		@media (min-width:801px) {
			max-width: 728px;
		}

		@media (min-width:1281px) {
			max-width: 1220px;
		}
	}

	.bottom-btns {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		margin-top: 40px;

		@media (min-width:1281px) {
			justify-content: space-between;
			padding-top: 73px;
			border-top: 1px solid #E5E5E5;
		}

	}

	.config {
		background: #FFFFFF;
		box-shadow: 5px 4px 29px rgba(0, 0, 0, 0.1);
		padding: 35px 20px 20px;
		margin: 0 auto;
		width: 100%;
		transform: translate(0, -200%);
		position: fixed;
		overflow: auto;
		height: 100%;
		top: 0;
		left: 0;
		transition: all 0.3s linear;

		&.open {
			transition: all 0.3s linear;
			transform: translate(0, 0);
			z-index: 2;
		}



		.img {
			img {
				width: 100%;
			}
		}

		.total {
			border-top: 1px solid #000;
			padding-top: 20px;
			font-weight: normal;
			font-size: 22px;
			line-height: 26px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-top: 40px;
		}

		table {
			width: 100%;

			td {
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 30px;
				color: #000000;

				&.desc {
					max-width: 89px;
					font-weight: 300;
					font-size: 18px;
					line-height: 21px;
					word-wrap: break-word;
				}
			}
		}

		.close {
			margin-top: 0;
		}

		.title {
			text-align: left;
			margin-bottom: 0;
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 7px;
		}

		.name {
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 7px;
		}

		.img {
			margin-bottom: 20px;
		}

		.subtitle {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;

			color: #616161;
		}

		.progressbar {
			margin-top: 17px;
			margin-bottom: 20px;
			width: 100%;
			height: 26px;
			background-color: #F1F1F1;

			.load {
				height: 100%;
				background-color: #000000;
			}
		}

		@media (min-width:801px) {
			width: 728px;
			left: calc(50% - 364px);
		}

		@media (min-width:1281px) {
			position: static;
			transform: none;
			max-width: 395px;
			margin: 0;

			.close {
				display: none;
			}

			&.open {
				position: static;
				transform: none;
			}

			table {
				td {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 21px;

					color: #000000;

					&:nth-child(3) {
						font-style: normal;
						font-weight: normal;
						font-size: 24px;
						line-height: 30px;
						/* identical to box height, or 125% */

						color: #000000;
					}
				}
			}

			.total {
				.text {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 21px;
				}

				.value {
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 28px;

					color: #000000;
				}
			}
		}

		&.final {
			transform: none;

			min-width: 100%;
			min-height: 100%;

			.total {
				flex-flow: column;

				.final-btn {
					margin-top: 40px;
				}
			}

			.close {
				display: none;
			}

			.subtitle {
				display: none;
			}

			.progressbar {
				display: none;
			}

			.row-2 {
				display: flex;
				flex-flow: column;
			}

			.sub {
				display: none;
			}

			.counter {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
			}

			.total-btns {
				margin-left: 10px;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: 15px;

				a {
					width: 24px;
					height: 24px;
					color: #929292;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px #929292 solid;
					border-radius: 50%;
				}
			}

			.desc {
				/* 				display: inline-block;

				&+td {
					display: inline-block;
				} */
			}

			.print {
				display: none;
			}

			td {
				span {
					display: inline-block;
				}

				&.desc {
					span {
						display: none;
					}
				}
			}

			@media (min-width:801px) {
				max-width: 768px;
				margin: 0 auto;
				min-width: auto;
				position: static;
			}

			@media (min-width:1281px) {
				box-shadow: none;
				max-width: 1220px;
				margin: 0 auto;
				width: auto;
				.counter {
					gap: 24px;
					width: fit-content;
					margin-left: auto;
				}

				.value {
					border-collapse: collapse;

					.price {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 21px;
						/* identical to box height */


						color: #000000;
					}

					.desc {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 18px;
						/* or 100% */


						color: #000000;
					}

					tr {
						&:first-child {
							td {
								padding: 0;
							}
						}

						&:nth-child(2) {
							td {
								padding: 0 0 15px;
							}
						}

						&:nth-child(n + 2) {
							td {
								border-bottom: 1px solid #F2F2F2;
							}
						}

						&:last-child {
							td {
								border-bottom: 1px solid #C4C4C4;
							}
						}
					}

					td {
						padding: 15px 0;

						span {
							display: none;
						}

						&.desc {
							width: 65%;

							span {
								display: inline-block;
							}
						}
					}
				}

				.total {
					margin-top: 0;
					border-top: none;
					padding-top: 40px;

					table {
						border-collapse: collapse;

						tr {
							td {
								&:last-child {
									text-align: right;
									font-size: 24px;
									line-height: 28px;

									color: #000000;
								}

								&:first-child {
									font-style: normal;
									font-weight: normal;
									font-size: 20px;
									line-height: 23px;

									color: #000000;
								}
							}

							&:nth-last-child(n + 1) {
								td {
									padding-bottom: 30px;
								}
							}

							&:last-child {
								td {
									padding-top: 30px;
									border-top: 1px solid #F2F2F2;

									&:last-child {
										font-weight: 500;
									}
								}

							}
						}
					}
				}

				.sub {
					display: block;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					margin-top: 4px;
					color: #000000;
				}

				.price {
					width: fit-content;
				}

				.print {
					margin: 0 0 0 auto;
					width: fit-content;
					display: flex;
					align-items: center;
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 28px;
					text-align: center;
					gap: 15px;
					justify-content: flex-end;
					color: #000000;
				}

				.title {
					font-size: 28px;
					line-height: 33px;
					margin-bottom: 30px;
				}

				.name {
					display: none;
				}

				.separator {
					display: flex;
					gap: 48px;
				}

				.right {
					order: 2;
					flex: 0 0 50%;
				}

				.left {
					flex: 0 0 calc(50% - 48px);
				}
			}
		}
	}

	.content-wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;

		@media (min-width:1281px) {
			justify-content: space-between;
		}
	}

	.curt-name {
		font-weight: 500;
		font-size: 18px;
		line-height: 21px;
		color: #000000;
		text-align: center;
	}

	.curt-desc {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		margin: 20px 0;
		color: #000000;
	}

	.curt-price {
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 23px;
		text-align: center;
		color: #000000;
		margin-bottom: 20px;
	}

	.item-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 20px;

		&.winter {
			justify-content: center;

			@media (min-width:1281px) {
				justify-content: space-between;
			}

			.item .img img {
				height: auto;
			}
		}
	}

	.item {

		width: 290px;
		border: 1px solid #DCDCDC;
		//cursor: pointer;
		padding: 15px 15px 20px;

		.wrapper {
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
		}

		.window-btns {
			width: 110px;
			margin: 20px auto;
			display: flex;
			flex-flow: column;
			align-items: center;

			.btns {
				margin-bottom: 9px;
			}

			.col {
				display: flex;
				flex-flow: column nowrap;
				gap: 20px;
			}

			.wind-btn {
				background: #F2F2F2;
				width: 50px;
				height: 34px;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				text-align: center;

				color: #000000;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					background: #FFFFFF;
					border: 1px solid #000000;
				}

				&.on {
					background: #FFFFFF;
					border: 1px solid #000000;
				}
			}

			.helper-container {
				position: relative;
				align-self: flex-end;
			}

			.helper-content {
				display: none;
			}

			.helper {
				font-size: 11px;
				border: 1px solid #767676;
				width: 17px;
				height: 17px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				position: absolute;
				left: calc(100% + 17px);
				top: -30px;

				&:hover {
					&+.helper-content {
						display: block;
						position: absolute;
						top: 0;
						left: 100%;
						background: #FFFFFF;
						box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 16px;
						width: max-content;
						color: #000000;
						padding: 12px;

						.row {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&.main {
			height: 668px;
		}

		&:hover:not(.logo) {
			border: 1px solid #42425C;
		}

		&.logo {
			background: #F2F2F2;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.img {
			margin-bottom: 14px;

			img {
				width: 260px;
				height: 260px;
			}
		}

		table {
			td {
				padding-bottom: 21px;
				font-size: 20px;
				line-height: 30px;
			}

			.desc {
				padding-right: 17px;
			}
		}

		.colors {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

		}

		.color {
			border-radius: 50%;
			width: 26px;
			height: 26px;
		}

		.color-price {
			margin: 12px 0 1px;
			font-size: 16px;
			line-height: 30px;

			text-align: center;
			color: #272727;
		}

		.row {
			margin-bottom: 25px;

			.subtitle {
				font-weight: 500;
				font-size: 24px;
				line-height: 30px;
				color: #000000;
				display: inline-block;
				margin-right: 10px;
			}

			.description {
				font-weight: normal;
				color: #000000;
				font-size: 24px;
				line-height: 30px;
				display: inline-block;
			}
		}



		.btns {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}
	}

	.close {
		margin-top: 17px;
		margin-bottom: 11px;
		width: 17px;
		height: 17px;
		border-top: 1px solid #000000;
		transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
		position: relative;
		display: flex;
		justify-content: center;
		left: calc(100% - 34px);

		&::after {
			position: absolute;
			top: -1px;
			content: "";
			width: 17px;
			height: 1px;
			background-color: #000;
			transform: rotate(90deg);
			//transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
		}
	}

	.gray-btn {
		background-color: #fff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 335px;
		height: 70px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */
		text-align: center;
		color: #D0D0D0;
		border: 1px solid #D0D0D0;

		@media (min-width:1281px) {
			width: 290px;
			height: 85px;
			color: #929292;
		}

		&:hover {
			background: #0F0F1D;
			color: #FFFFFF;
			border: none;
		}

		&:disabled {
			color: #FFFFFF;
			background: #9B9B9B;
			border: none;
		}

		&:active {
			color: #FFFFFF;
			background: #2F2E50;
			border: none;
		}
	}

	.white-btn {
		background-color: #fff;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 240px;
		height: 80px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */
		text-align: center;
		color: #000000;
		border: 1px solid #D0D0D0;
		display: none;

		@media (min-width:1281px) {
			display: flex;
		}



		&:hover {
			background: #0F0F1D;
			color: #FFFFFF;
			border: none;
		}

		&:disabled {
			color: #FFFFFF;
			background: #9B9B9B;
			border: none;
		}

		&:active {
			color: #FFFFFF;
			background: #2F2E50;
			border: none;
		}
	}

	.main-btn {
		background-color: #42425C;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 260px;
		height: 52px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */
		text-align: center;
		color: #FFFFFF;

		&:hover {
			background: #0F0F1D;
			color: #FFFFFF;
		}

		&:disabled {
			color: #FFFFFF;
			background: #848297;
		}

		&:active {
			color: #FFFFFF;
			background: #2F2E50;
		}
	}

	.second-btn {
		background-color: #fff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 260px;
		height: 52px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */
		text-align: center;
		color: #000000;
		border: 1px solid #D0D0D0;



		&:hover {
			background: #0F0F1D;
			color: #FFFFFF;
			border: none;
		}

		&:disabled {
			color: #FFFFFF;
			background: #9B9B9B;
			border: none;
		}

		&:active {
			color: #FFFFFF;
			background: #2F2E50;
			border: none;
		}
	}

	.final-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;

		font-style: normal;
		font-weight: normal;

		/* identical to box height, or 150% */
		text-align: center;
		color: #fff;
		background: #2A2A32;
		height: 65px;
		font-size: 20px;
		line-height: 23px;

		@media (min-width:1281px) {
			height: 86px;
			font-size: 24px;
			line-height: 30px;
		}

		&:hover,
		&active {
			background: #0F0F1D;
		}
	}

	.popup-wrapper {
		position: fixed;
		z-index: 2;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.25);
		top: 0;
		left: 0;

		.popup {
			margin: 60px auto;
			max-width: 375px;
			padding: 19px 14px 54px;
			background: #FFFFFF;
			box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
			display: flex;
			flex-flow: column;

			input {
				width: 100%;
				height: 65px;
				margin-bottom: 18px;
				border: 1px solid #BDBDBD;
				padding: 10px 14px;
				display: flex;
				align-items: center;

				&::placeholder {
					color: #BDBDBD;
				}
			}

			.final-btn {
				margin-top: 23px;
			}

			.close-svg {
				align-self: flex-end;
				margin-bottom: 37px;
			}
		}
	}
}